<template>
    <div class="d-flex flex-column h-100">
        <vue-custom-scrollbar
					class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
							:title="$t('user.pageTitle')"
							:breadcrumb="[
								{ text: $t('home'), href: '/' },
								{
									text: $t('user.pageTitle'),
									href: '/admin/users'
								},
								{
									text: $t('create'),
									active: true
								}
							]"
            />
            <ts-loading-banner :loading="loading">
							<div class="row">
								<div class="col-md-8">
									<ts-panel>
										<ts-panel-wrapper>
											<a-collapse :bordered="false">
												<a-collapse-panel :header="$t('user.specialPermission')">
													<Permission v-model="model" />
												</a-collapse-panel>
											</a-collapse>
										</ts-panel-wrapper>
									</ts-panel>
								</div>
								<div class="col-md-4 tw-space-y-5">
										<UserType v-model="model" :validate="errors" @loading="(value) => loading = value" />
										<UserAccount v-model="model" :validate="errors" />
										<UserRole v-model="model" :validate="errors" />
								</div>
							</div>
            </ts-loading-banner>
        </vue-custom-scrollbar>
        <div
					id="footer"
					class="app-footer m-0 tw-flex tw-justify-end tw-space-x-3 tw-bg-white"
        >
            <ts-button @click.prevent="$router.push({ name: 'user' })">{{
                $t("cancel")
            }}</ts-button>
            <ts-button
							color="primary"
							outline
							:waiting="waiting_new"
							@click.prevent="onSaveAddNew"
							>{{ $t("saveAddNew") }}</ts-button
            >
            <ts-button
							color="primary"
							:waiting="waiting"
							@click.prevent="onSave"
							>{{ $t("save") }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import UserAccount from "./components/account.vue";
import UserRole from "./components/role.vue";
import Permission from './components/permision.vue'
import UserType from './components/user-type.vue'
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";

export default {
    name: "userCreate",
    components: {
        UserAccount,
        UserRole,
				Permission,
				UserType
    },
    data() {
			return {
				loading: false,
				waiting: false,
				waiting_new: false,
				errors: new Errors(),
				model: {
					user_type: 'admin',
					shop_id: undefined,
					agency_id: undefined,
					driver_id: undefined,
					employee_id: undefined,
					user_id: 0,
					user_name: "",
					email: "",
					roles: null,
					permissions: [],
					disable: false,
					password: null,
					password_confirmation: null
				}
			};
    },
    created() {
			AppOptions.appContentFullHeight = true;
			AppOptions.appContentClass = "p-0";
    },
    methods: {
			...mapActions("auth/user", ['getUserRole', 'getPermissions', 'getFormViewData']),
			async fetchResource() {
				this.loading = true;
				if (this.$whois.agency()) {
					this.model.user_type = 'agency'
				}
				await this.getUserRole({
					user_id: this.$whois.admin() ? 'admin' : 'agency'
				});
				await this.getPermissions({
					user_id: this.$whois.admin() ? 'admin' : 'agency'
				});
				await this.getFormViewData({ params: {}, index: undefined });
				this.loading = false;
			},
			onSaveAddNew() {
				this.errors = new Errors();
				this.waiting_new = true;
				this.$store
					.dispatch("auth/user/store", this.model)
					.then(response => {
						this.$notify({ type: "success", text: response.message });
						this.clearInput();
					})
					.catch(error => {
						this.errors = new Errors(error.errors);
						this.$notify({ type: "error", text: error.message });
					})
					.finally(() => {
						this.waiting_new = false;
					});
			},
			onSave() {
				this.errors = new Errors();
				this.waiting = true;
				this.$store
					.dispatch("auth/user/store", this.model)
					.then(response => {
						this.$notify({ type: "success", text: response.message });
						this.clearInput();
						this.$router.push({ name: "user" });
					})
					.catch(error => {
						this.errors = new Errors(error.errors);
						this.$notify({ type: "error", text: error.message });
					})
					.finally(() => {
						this.waiting = false;
					});
			},
			clearInput() {
				this.model.shop_id = undefined
				this.model.agency_id = undefined
				this.model.driver_id = undefined
				this.model.employee_id = undefined
				this.model.user_id = 0
				this.model.user_name = ""
				this.model.email = ""
				this.model.roles = null
				this.model.permissions = []
				this.model.disable = false
				this.model.password = null
				this.model.password_confirmation = null
			}
    },
    beforeRouteEnter(to, from, next) {
			next(vm => {
					vm.fetchResource();
			});
    },
    beforeRouteLeave(to, from, next) {
			AppOptions.appContentFullHeight = false;
			AppOptions.appContentClass = "";
			next();
    }
};
</script>
